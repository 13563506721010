// Imports


// Plugins
const plugins = [{
  location: "/Users/matthewbridges/projects/personal/matt-static",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins